// Need to hoist public path setting to top so ES6 imports do not come before it.
import {ApplicationSuite} from '@verily-src/phaf-runtime-helpers';
import {config as phafConfig} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import {api as phafUnifiedApi, UnifiedApi} from '@verily-src/phaf-unified-api';
import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import ErrorElement, {
  ErrorElementWithoutRoute,
} from './components/global-error/global-error';
import App from './index';
import './publicPath';
import reportWebVitals from './reportWebVitals';

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: App,
  errorBoundary() {
    // Customize the root error boundary for your microfrontend here.
    return phafConfig.getBoolean(
      'FEATURE_WORKFLOW_DRIVEN_ENROLLMENT_ENABLED'
    ) &&
      (phafUnifiedApi as UnifiedApi).getApplicationSuite() ===
        ApplicationSuite.VERILY_ME ? (
      <ErrorElementWithoutRoute />
    ) : (
      <ErrorElement />
    );
  },
});

export const {bootstrap, mount, unmount} = lifecycles;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
