import {GrpcWebFetchTransport} from '@protobuf-ts/grpcweb-transport';
import {EnrollmentClient as Client} from '@verily-src/verily1-protos/enrollment/bff/api/v2/server.client';
import {completeEnrollmentSWRFetcher} from './completeEnrollment';
import {getConfig} from './getConfig';
import {getUserState, getUserStateSWRFetcher} from './getUserState';
import {recordEnrolleeEvent} from './recordEnrolleeEvent';
import {reportError} from './reportError';

export const client = new Client(
  new GrpcWebFetchTransport({
    baseUrl: '/api',
  })
);

export const api = {
  completeEnrollmentSWRFetcher,
  getConfig,
  getUserState,
  getUserStateSWRFetcher,
  recordEnrolleeEvent,
  reportError,
};
