import {useSnackbar} from '@verily-src/react-design-system';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Navigate, useLocation} from 'react-router-dom';
import {useDomain} from '../../../hooks/useDomain';
import {useProfile} from '../../../hooks/useProfile';
import {api} from '../../../lib/api';
import {createAccountPath, profilePath} from '../../../types/route';
import {toCommonAssetsPath} from '../../utils';
import VerifyEmailLayout from './layout';

type VerifyEmailLocationState = {
  email?: string;
  auth0Id?: string;
};

export default function VerifyEmail() {
  const logo = toCommonAssetsPath('verily_logo.png');
  const {t} = useTranslation();
  const {profileName} = useProfile();
  const {domainName} = useDomain();
  const snackbar = useSnackbar();
  const location = useLocation();
  const {email, auth0Id} = (location.state as VerifyEmailLocationState) ?? {};
  const [resendPasswordEmailIsLoading, setResendPasswordEmailIsLoading] =
    useState(false);

  const resendPasswordResetEmail = async () => {
    setResendPasswordEmailIsLoading(true);
    try {
      await api
        .resendPasswordResetEmail(email, profileName, domainName, auth0Id)
        .then(() => snackbar.show(t('verify-email.resend-email-success')));
    } catch (error) {
      // If we get here, we have an unexpected error
      snackbar.show(t('verify-email.resend-email-failure', 'error'));
    }
    setResendPasswordEmailIsLoading(false);
  };

  // if no email is received, assume the user has not completed
  // the previous step and redirect them back to create account page
  if (!email) {
    return <Navigate to={createAccountPath(profilePath(profileName))} />;
  }

  return (
    <VerifyEmailLayout
      email={email}
      logo={logo}
      resendPasswordResetEmail={resendPasswordResetEmail}
      resendPasswordEmailIsLoading={resendPasswordEmailIsLoading}
    />
  );
}
